// places.actions.ts

import { Place } from '@index/interfaces';
import { createAction, props, union } from '@ngrx/store';
import { GthEventItemModel } from '@sentinels/models';
import { GthPlaceModel } from '@sentinels/models/place';

export enum PlacesActionTypes {
  PlacesAddOne = '[Places] Add One',
  PlacesUpdate = '[Places] Update Place',
  PlacesLoadListByLatLng = '[Places] Load List By Lat Lng',
  PlacesLoadListSuccess = '[Places] Load List Success',
  PlacesLoadListError = '[Places] Load List Error',
  PlacesLoadOneById = '[Places] Load One By Id',
  PlacesLoadOneSuccess = '[Places] Load One Success',
  PlacesLoadOneError = '[Places] Load One Error',
  PlacesLoadEventsByPlaceId = '[Places] Load Events By Place Id',
  PlacesLoadEventsByPlaceIdSuccess = '[Places] Load Events By Place Id Success',
  PlacesLoadEventsByPlaceIdError = '[Places] Load Events By Place Id Error',
}

export const placesAddOne = createAction(
  PlacesActionTypes.PlacesAddOne,
  props<{ place: Place }>(),
);

export const updatePlace = createAction(
  PlacesActionTypes.PlacesUpdate,
  props<{ place: Place }>(),
);

export const placesLoadListByLatLng = createAction(
  PlacesActionTypes.PlacesLoadListByLatLng,
  props<{ lat: number; lng: number; bounds: google.maps.LatLngBoundsLiteral }>(),
);

export const placesLoadOneById = createAction(
  PlacesActionTypes.PlacesLoadOneById,
  props<{ id: string }>(),
);

export const placesLoadListSuccess = createAction(
  PlacesActionTypes.PlacesLoadListSuccess,
  props<{ places: GthPlaceModel[] }>(),
);

export const placesLoadListError = createAction(
  PlacesActionTypes.PlacesLoadListError,
  props<{ error: unknown }>(),
);

export const placesLoadEventsByPlaceId = createAction(
  PlacesActionTypes.PlacesLoadEventsByPlaceId,
  props<{ placeId: string }>(),
);

export const placesLoadEventsByPlaceIdSuccess = createAction(
  PlacesActionTypes.PlacesLoadEventsByPlaceIdSuccess,
  props<{ events: GthEventItemModel[] }>(),
);

export const placesLoadEventsByPlaceIdError = createAction(
  PlacesActionTypes.PlacesLoadEventsByPlaceIdError,
  props<{ error: unknown }>(),
);


export const allPlaces = union({
  placesLoadListByLatLng,
  placesLoadListSuccess,
  placesLoadListError,
  placesLoadOneById,
  placesLoadEventsByPlaceId,
  placesLoadEventsByPlaceIdSuccess,
  placesLoadEventsByPlaceIdError,
});

export type PlacesActionsUnion = typeof allPlaces;
